import React from 'react';
import { Link } from 'react-router-dom';

const SearchCard = ({ image_url, name, industry, id, loading = false,border=true , close}) => {

    const handleClose = ()=>{
        close();
    }

    if (!loading) {
        return (
            <>
                <Link to="/profile" state={{ profile_id: id }} onClick={handleClose} >
                    <div className={`flex items-center  w-full gap-5 ${border?'border-b':''} px-4 py-2 hover:bg-color-5 dark:hover:bg-color-4`}>
                        <span className="">
                            <div className="size-8 rounded-full overflow-hidden bg-color-5">
                                <img src={image_url} alt="" className="w-full h-full" />
                            </div>

                        </span>

                        <span className='flex flex-col' >
                            <div className="max-lg:text-sm font-outfit-medium text-color-3 dark:text-color-6">
                                {name}
                            </div>
                            <div className="text-xs text-color-4 font-outfit dark:text-color-5">
                                {industry}
                            </div>
                        </span>
                    </div>
                </Link>

            </>
        )
    }
    else {
        return (
            <>

                <div className="flex items-center  w-full justify-between">
                    <span className="flex items-center gap-5">
                        <div className="size-8 animate-pulse rounded-full overflow-hidden bg-color-5">

                        </div>
                        <div className='w-20 h-2 bg-color-5 rounded-full animate-pulse'></div>
                    </span>
                    <span className='w-20 h-2 bg-color-5 rounded-full animate-pulse'></span>
                </div>


            </>
        )

    }
}

export default SearchCard;