import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import userApi from '../../../api/admin/user/userApi';

const LoginGraph = () => {
  const [data, setData] = useState([]);
  const [groupBy, setGroupBy] = useState('month');



  const handleGroupByChange = (e) => {

    setGroupBy(e.target.value);

  };


  const fetchLoginStats = async (groupby)=>{
    try{
        const response = await userApi.getLoginStats(groupby);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedData = response.map((item) => {
          let data = {};
          if(groupby==='week'){
           data = {
            month: `${item.week}-${item.year}`,
              logins: item.logins
            };
          }
          else if(groupby==='year'){
            data = {
              month: `${item.year}`,
              logins: item.logins
            };
          }
          else{
            data = {
              month: `${monthNames[item.month - 1]}-${item.year}`,
              logins: item.logins
            };
          }
          
          return data;
        });
          setData(formattedData);
    }
   catch(error){
    console.error('Error fetching data:', error);
   }
  }
  useEffect(() => {
    fetchLoginStats(groupBy);
   
  }, [groupBy]);

  return (

    <>
       <div className='my-5 '>
        <div className="flex items-center w-1/2 lg:w-44  cursor-pointer relative text-color-2">
          <select name="" onChange={(e)=>handleGroupByChange(e)} value={groupBy} className="appearance-none bg-transparent outline-none text-sm font-outfit-semibold  cursor-pointer px-5 w-full py-3 border-2 relative z-20 dark:text-color-5">
            <option className='dark:text-color-3' value="month">Month</option>
            <option className='dark:text-color-3' value="week">Week</option>
            <option className='dark:text-color-3' value="year">Year</option>
          </select>
          <span className="absolute right-4 z-10">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-[#1C1B1F] dark:fill-color-5">
              <mask id="mask0_99_183" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_99_183)">
                <path d="M10.0001 12.4792C9.88897 12.4792 9.7848 12.4618 9.68758 12.4271C9.59036 12.3924 9.50008 12.3333 9.41675 12.25L5.58341 8.41666C5.43064 8.26388 5.35425 8.06944 5.35425 7.83333C5.35425 7.59722 5.43064 7.40277 5.58341 7.24999C5.73619 7.09722 5.93064 7.02083 6.16675 7.02083C6.40286 7.02083 6.5973 7.09722 6.75008 7.24999L10.0001 10.5L13.2501 7.24999C13.4029 7.09722 13.5973 7.02083 13.8334 7.02083C14.0695 7.02083 14.264 7.09722 14.4167 7.24999C14.5695 7.40277 14.6459 7.59722 14.6459 7.83333C14.6459 8.06944 14.5695 8.26388 14.4167 8.41666L10.5834 12.25C10.5001 12.3333 10.4098 12.3924 10.3126 12.4271C10.2154 12.4618 10.1112 12.4792 10.0001 12.4792Z" fill="" />
              </g>
            </svg>

          </span>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400} >
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line 
          type="monotone" 
          dataKey="logins" 
          stroke="#E8B923" 
          dot={<Dot r={4} fill="#E8B923" />} // Customize dot here
        />
      </LineChart>
    </ResponsiveContainer>
    </>
   
  );
};

export default LoginGraph;