import axiosInstance from '../axios/axios_config';


const send=async (data)=>{
    const response = await axiosInstance.post(`/contact`,data);

    return response.data;
}

const contact= {
    send,  
    
};
export default contact;

