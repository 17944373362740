import axiosAdminInstance from '../axios/admin_axios_config';

const get=async (get_quantity=10,page=1)=>{
    
    const response = await axiosAdminInstance.get(`/admin/contact?get_quantity=${get_quantity}&page=${page}`);   
    return response.data;
}


const destroy = async (id)=>{
    
    const response = await axiosAdminInstance.delete(`/admin/contact/${id}`);

    return response.data;
}



const profileApi = {
    get,
  
    destroy,
 
}
export default profileApi;