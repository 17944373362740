import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import sliderApi from '../../api/user/slider/sliderApi';

import Hero from "./Hero";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const HeroSlider = () => {

    const [sliderData, setSliderData] = useState([]);

    const [basePath, setBasePath] = useState('');

    const fetchSliderData = async () => {

        try {
            const response = await sliderApi.getHomeSlider();

            const { data, base_path } = response;
            setSliderData(data);
            setBasePath(base_path);
        } catch (error) {
            console.error('Error fetching profile data:', error.response);
        }
    };

    useEffect(() => {
        fetchSliderData();
    }, []);


    return (
        <>

            {
                sliderData.length > 0 ? <Slider sliderData={sliderData} base_path={basePath} /> : <Hero />
            }

        </>
    )
}


const Slider = ({ sliderData, base_path }) => {

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    useEffect(() => {
        // Ensure Swiper has initialized before assigning navigation elements
        if (prevRef.current && nextRef.current) {
            prevRef.current.classList.add('custom-prev');
            nextRef.current.classList.add('custom-next');
        }
    }, []);
    return (
        <>
            <div className='relative'>

                <Swiper
                    modules={[Navigation, Pagination, Autoplay, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}

                    pagination={{ clickable: true }}
                    autoplay={true}
                    loop={true}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                    }}

                >

                    {
                        sliderData.map((slide, index) => {
                            return <SwiperSlide key={index}>
                                <div className=" bg-color-8 max-h-[700px] lg:h-[80vh] h-[220px] flex " style={{ backgroundColor: `${slide.bg_color}` }}>
                                    <div className="w-full pl-8 pt-5 lg:pl-32 lg:pt-32 space-y-5 lg:space-y-20 lg:max-w-[50%] min-w-[40%] max-lg:self-center pr-5">
                                        <div>
                                            <div className="text-xs lg:text-4xl font-outfit-extrabold text-color-4">{slide.title1}</div>
                                            <div className={`font-outfit-extrabold  ${slide.title2.length > 11 ? 'lg:text-[50px] max-lg:text-xl' : 'lg:text-[86px] max-lg:text-3xl'} text-color-3 overflow-ellipsis overflow-hidden`}>{slide.title2}</div>
                                            <div className="text-2xl lg:text-[56px] text-color-3 font-savoye mt-2">{slide.title3}</div>
                                        </div>
                                        <div className="flex items-center">
                                            <span>
                                                <svg className="max-lg:hidden" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_25_85" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                        <rect width="24" height="24" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_25_85)">
                                                        <path d="M16.15 13H5C4.71667 13 4.47917 12.9042 4.2875 12.7125C4.09583 12.5208 4 12.2833 4 12C4 11.7167 4.09583 11.4792 4.2875 11.2875C4.47917 11.0958 4.71667 11 5 11H16.15L13.3 8.15C13.1 7.95 13.0042 7.71666 13.0125 7.45C13.0208 7.18333 13.1167 6.95 13.3 6.75C13.5 6.55 13.7375 6.44583 14.0125 6.4375C14.2875 6.42916 14.525 6.525 14.725 6.725L19.3 11.3C19.4 11.4 19.4708 11.5083 19.5125 11.625C19.5542 11.7417 19.575 11.8667 19.575 12C19.575 12.1333 19.5542 12.2583 19.5125 12.375C19.4708 12.4917 19.4 12.6 19.3 12.7L14.725 17.275C14.525 17.475 14.2875 17.5708 14.0125 17.5625C13.7375 17.5542 13.5 17.45 13.3 17.25C13.1167 17.05 13.0208 16.8167 13.0125 16.55C13.0042 16.2833 13.1 16.05 13.3 15.85L16.15 13Z" fill="#E8B923" />
                                                    </g>
                                                </svg>

                                                <svg className="lg:hidden" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_153_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                        <rect width="20" height="20" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_153_7)">
                                                        <path d="M13.4583 10.8333H4.16665C3.93054 10.8333 3.73262 10.7534 3.5729 10.5937C3.41317 10.434 3.33331 10.2361 3.33331 9.99997C3.33331 9.76386 3.41317 9.56594 3.5729 9.40622C3.73262 9.2465 3.93054 9.16664 4.16665 9.16664H13.4583L11.0833 6.79164C10.9166 6.62497 10.8368 6.43052 10.8437 6.2083C10.8507 5.98608 10.9305 5.79164 11.0833 5.62497C11.25 5.4583 11.4479 5.3715 11.6771 5.36455C11.9062 5.35761 12.1041 5.43747 12.2708 5.60414L16.0833 9.41664C16.1666 9.49997 16.2257 9.59025 16.2604 9.68747C16.2951 9.78469 16.3125 9.88886 16.3125 9.99997C16.3125 10.1111 16.2951 10.2152 16.2604 10.3125C16.2257 10.4097 16.1666 10.5 16.0833 10.5833L12.2708 14.3958C12.1041 14.5625 11.9062 14.6423 11.6771 14.6354C11.4479 14.6284 11.25 14.5416 11.0833 14.375C10.9305 14.2083 10.8507 14.0139 10.8437 13.7916C10.8368 13.5694 10.9166 13.375 11.0833 13.2083L13.4583 10.8333Z" fill="#E8B923" />
                                                    </g>
                                                </svg>


                                            </span>
                                            <span>
                                                <Link to="ranking" className="text-color-1 underline decoration-color-1 lg:text-xl font-outfit-medium text-sm">Full Ranking</Link>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-0">
                                        <img src={`${base_path}/${slide.image}`} alt="" className="w-full h-full" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        })

                    }


                </Swiper>
                <div className='flex justify-between absolute top-1/2 -translate-y-1/2 w-full z-20 '>

                    <div ref={prevRef} className="bg-black/30 group size-8 lg:size-10 relative rounded-tr-lg rounded-br-lg group cursor-pointer" >
                        <svg className="fill-color-5 group-hover:fill-color-3 rotate-90 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-105" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <mask id="mask0_99_183" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                <rect width="20" height="20" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_99_183)">
                                <path d="M10.0001 12.4792C9.88897 12.4792 9.7848 12.4618 9.68758 12.4271C9.59036 12.3924 9.50008 12.3333 9.41675 12.25L5.58341 8.41666C5.43064 8.26388 5.35425 8.06944 5.35425 7.83333C5.35425 7.59722 5.43064 7.40277 5.58341 7.24999C5.73619 7.09722 5.93064 7.02083 6.16675 7.02083C6.40286 7.02083 6.5973 7.09722 6.75008 7.24999L10.0001 10.5L13.2501 7.24999C13.4029 7.09722 13.5973 7.02083 13.8334 7.02083C14.0695 7.02083 14.264 7.09722 14.4167 7.24999C14.5695 7.40277 14.6459 7.59722 14.6459 7.83333C14.6459 8.06944 14.5695 8.26388 14.4167 8.41666L10.5834 12.25C10.5001 12.3333 10.4098 12.3924 10.3126 12.4271C10.2154 12.4618 10.1112 12.4792 10.0001 12.4792Z" fill="" />
                            </g>
                        </svg>
                    </div>
                    <div ref={nextRef} className="bg-black/30 group size-8 lg:size-10 relative rounded-tr-lg rounded-br-lg rotate-180 cursor-pointer group">
                        <svg className="fill-color-5 group-hover:fill-color-3 rotate-90 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-105" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <mask id="mask0_99_183" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                <rect width="20" height="20" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_99_183)">
                                <path d="M10.0001 12.4792C9.88897 12.4792 9.7848 12.4618 9.68758 12.4271C9.59036 12.3924 9.50008 12.3333 9.41675 12.25L5.58341 8.41666C5.43064 8.26388 5.35425 8.06944 5.35425 7.83333C5.35425 7.59722 5.43064 7.40277 5.58341 7.24999C5.73619 7.09722 5.93064 7.02083 6.16675 7.02083C6.40286 7.02083 6.5973 7.09722 6.75008 7.24999L10.0001 10.5L13.2501 7.24999C13.4029 7.09722 13.5973 7.02083 13.8334 7.02083C14.0695 7.02083 14.264 7.09722 14.4167 7.24999C14.5695 7.40277 14.6459 7.59722 14.6459 7.83333C14.6459 8.06944 14.5695 8.26388 14.4167 8.41666L10.5834 12.25C10.5001 12.3333 10.4098 12.3924 10.3126 12.4271C10.2154 12.4618 10.1112 12.4792 10.0001 12.4792Z" fill="" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>

        </>
    )
}
export default HeroSlider;


