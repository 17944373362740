import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import profileApi from '../../../api/admin/profile/profileApi';

const ExcelUpload = ({modelClose}) => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const excelBulkUpload = async (jsonData) => {
        try {
            await profileApi.excelUpload(jsonData);
            setMessage('Excel Uploaded Successfully');
            setError(false);
            setFile(null);
        }
        catch (error) {
            console.error('Error uploading data:', error);
            setMessage('Error occur');
            setError(true);
        }
    }

    const handleFileUpload = async (e) => {
        e.preventDefault();
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            excelBulkUpload(jsonData);
            e.target.reset();

        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <div className='w-full h-full relative'>
            <div className="modal bg-white dark:bg-color-2 rounded-lg shadow-lg w-11/12 max-w-lg mx-auto my-20 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="modal-header flex items-start justify-between p-6 pb-4">
                    <div className="modal-logo">
                        <span className="logo-circle w-14 h-14 flex justify-center items-center rounded-full bg-primary-accent">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 512 419.116">
                                <defs>
                                    <clipPath id="clip-folder-new">
                                        <rect width="512" height="419.116" />
                                    </clipPath>
                                </defs>
                                <g id="folder-new" clipPath="url(#clip-folder-new)">
                                    <path id="Union_1" data-name="Union 1" d="M16.991,419.116A16.989,16.989,0,0,1,0,402.125V16.991A16.989,16.989,0,0,1,16.991,0H146.124a17,17,0,0,1,10.342,3.513L227.217,57.77H437.805A16.989,16.989,0,0,1,454.8,74.761v53.244h40.213A16.992,16.992,0,0,1,511.6,148.657L454.966,405.222a17,17,0,0,1-16.6,13.332H410.053v.562ZM63.06,384.573H424.722L473.86,161.988H112.2Z" fill="currentColor" stroke="" strokeWidth="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                    <button onClick={()=>modelClose(false)} className="btn-close flex items-center justify-center w-9 h-9 rounded-md border-none bg-transparent hover:bg-primary-accent focus:bg-primary-accent group" >
                        <svg className='fill-none ' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" /><path className='fill-color-2 dark:fill-color-6 group-hover:fill-color-2' d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
                    </button>
                </div>
                <div className="modal-body p-6 pt-4">
                    <div>  {message && <p className={`mt-4 ${error?'text-red-600 dark:text-red-400':'text-green-600 dark:text-green-400'} `}>{message}</p>}</div>
                    <div className="modal-title font-bold text-color-2 dark:text-color-5">Upload a file</div>
                    <p className="modal-description text-color-2 dark:text-color-5">Attach the file below</p>
                    <form onSubmit={handleFileUpload} className="w-full mt-4">
                        <input type="file" onChange={handleFileChange} required className="block w-full file:cursor-pointer text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-color-1 file:text-white hover:file:bg-color-1" />
                        <div className="mt-6 flex justify-end">
                            <button onClick={()=>modelClose(false)} type="button" className="btn-secondary px-4 py-2 font-medium border-2 border-secondary rounded-md bg-transparent ml-3 dark:text-color-5">Cancel</button>
                            <button type="submit" className="btn-primary px-4 py-2 font-medium border-2 border-color-1 rounded-md bg-color-1 text-white ml-3">Upload File</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default ExcelUpload;
