import React from 'react';

const ExcelProfileFormat = () => {
  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_PUBLIC_URL}/assets/excel/excel_profile_format.xlsx`; // Replace with your file URL
    link.download = 'excel_profile_format.xlsx'; // The name with which the file will be downloaded
    link.click();
  };

  return (
    <button onClick={downloadFile} className='bg-color-1 flex items-center gap-2 hover:bg-color-1/80 w-fit px-10 rounded-md text-white py-3'>
        <span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24px" width="24px"><g strokeWidth="0" id="SVGRepo_bgCarrier"></g><g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / Download"> <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" stroke="#f1f1f1" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" id="Vector"></path> </g> </g></svg>
        </span>
        <span className='max-lg:hidden'>
        Download Excel Fromat

        </span>
        </button>
  );
};

export default ExcelProfileFormat;
